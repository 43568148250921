
import { importSync as i } from '@embroider/macros';
let d = window.define;
import "ember-cli-mirage/-embroider-implicit-modules.js";
import "ember-cli-page-object/-embroider-implicit-modules.js";
import "ember-math-helpers/-embroider-implicit-modules.js";
import "ember-modifier/-embroider-implicit-modules.js";
import "ember-page-title/-embroider-implicit-modules.js";
import "ember-sinon-qunit/-embroider-implicit-modules.js";
import "ember-truth-helpers/-embroider-implicit-modules.js";
import "tracked-built-ins/-embroider-implicit-modules.js";
import "ember-cli-content-security-policy/-embroider-implicit-modules.js";
import "ember-cli-inject-live-reload/-embroider-implicit-modules.js";
import "@ember/optional-features/-embroider-implicit-modules.js";
import "@ember/string/-embroider-implicit-modules.js";
import "ember-auto-import/-embroider-implicit-modules.js";
import "ember-cli-htmlbars/-embroider-implicit-modules.js";
import "@ember/test-helpers/-embroider-implicit-modules.js";
import "@glimmer/component/-embroider-implicit-modules.js";
import "@glimmer/tracking/-embroider-implicit-modules.js";
import "ember-qunit/-embroider-implicit-modules.js";
import "broccoli-asset-rev/-embroider-implicit-modules.js";
import "ember-bootstrap/-embroider-implicit-modules.js";
import "ember-cli-app-version/-embroider-implicit-modules.js";
import "ember-cli-browser-navigation-button-test-helper/-embroider-implicit-modules.js";
import "ember-cli-browserstack/-embroider-implicit-modules.js";
import "ember-cli-bundlesize/-embroider-implicit-modules.js";
import "ember-cli-clean-css/-embroider-implicit-modules.js";
import "ember-cli-clipboard/-embroider-implicit-modules.js";
import "ember-cli-dependency-checker/-embroider-implicit-modules.js";
import "ember-test-selectors/-embroider-implicit-modules.js";
import "ember-cli-deprecation-workflow/-embroider-implicit-modules.js";
import "ember-cli-sass/-embroider-implicit-modules.js";
import "ember-composable-helpers/-embroider-implicit-modules.js";
import "ember-source/-embroider-implicit-modules.js";
import "ember-decorators/-embroider-implicit-modules.js";
import "ember-fetch/-embroider-implicit-modules.js";
import "ember-intl/-embroider-implicit-modules.js";
import "ember-load-initializers/-embroider-implicit-modules.js";
import "ember-power-calendar/-embroider-implicit-modules.js";
import "ember-power-calendar-luxon/-embroider-implicit-modules.js";
import "ember-resolver/-embroider-implicit-modules.js";
import "loader.js/-embroider-implicit-modules.js";
import "include-api-in-build/-embroider-implicit-modules.js";
import "inject-app-version-in-meta-tag/-embroider-implicit-modules.js";
